// // import React, { useState, useEffect } from 'react';
// // import { Link, useLocation, useNavigate } from 'react-router-dom';
// // import '../navbar/Navbar.css';
// // import MenuIcon from '@mui/icons-material/Menu';
// // import CloseIcon from '@mui/icons-material/Close';
// // import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// // const Navbar = () => {
// //     const [nav, setNavbar] = useState(false);
// //     const location = useLocation();
// //     const navigate = useNavigate();
// //     const [userRole, setUserRole] = useState(null);
// //     const [userName, setUserName] = useState("");
// //     const [dropdownOpen, setDropdownOpen] = useState(false);

// //     useEffect(() => {
// //         // Check the role and user name in local storage when the component mounts
// //         const role = localStorage.getItem('role');
// //         const user = JSON.parse(localStorage.getItem('user'));
// //         setUserRole(role);
// //         if (user) {
// //             const { firstName, lastName } = user;
// //             setUserName(`${firstName[0]}${lastName[0]}`);
// //         }
// //     }, []);

// //     const changeBackground = () => {
// //         if (window.scrollY >= 80) {
// //             setNavbar(true);
// //         } else {
// //             setNavbar(false);
// //         }
// //     };

// //     useEffect(() => {
// //         window.addEventListener('scroll', changeBackground);
// //         return () => {
// //             window.removeEventListener('scroll', changeBackground);
// //         };
// //     }, []);

// //     const handleLogout = () => {
// //         localStorage.clear();
// //         window.location.reload();
// //     };
// //     const toggleDropdown = () => {
// //         setDropdownOpen(!dropdownOpen);
// //     };

// //     const disableClassesPaths = ['/buyPage', '/propertyDescription', '/userRegister','/userWishlist'];
// //     const disableClasses = disableClassesPaths.includes(location.pathname);
// //     const isBuyPage = location.pathname === '/buyPage';
// //     const isPropPage = location.pathname.startsWith('/propertyDescription');
// //     const isContactPage = location.pathname === '/contact';
// //     const isAboutPage = location.pathname === '/aboutUs';
// //     const isRegisterPage = location.pathname === '/userRegister';
// //     const user = location.pathname === '/user/userWishlist';

// //     return (
// //         <div>
// //             <nav className={`navbar nav-header navbar-expand-lg 
// //                 ${!disableClasses ? (nav ? 'nav active' : '') : ''} 
// //                 ${!disableClasses ? 'position-fixed' : ''} 
// //                 ${isBuyPage ? 'buyPage-background ' : ''} 
// //                 ${isAboutPage ? 'pagecolor ' : ''} 
// //                 ${isPropPage ? 'propPage-background' : ''}  
// //                 ${isContactPage ? 'contactPage-background' : ''}  
// //                 ${isRegisterPage ? 'buyPage-background' : ''}  
// //                 ${user ? 'buyPage-background' : ''}  
// //                  top-0 w-100 z-3`}>

// //                 <div className="container-fluid nav-padding d-flex justify-content-between align-items-center">
// //                     <Link className="nav-logo" to="/">
// //                         <img src="/assets/images/logo/logo.png" alt="" />
// //                     </Link>
// //                     <button className="navbar-toggler border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
// //                         <span> <MenuIcon style={{ color: '#fff' }} /></span>
// //                     </button>
// //                     <div className="collapse navbar-collapse justify-content-end">
// //                         <ul className="navbar-nav mb-2 mb-lg-0 nav-right-item d-flex align-items-center gap-5 me-4">
// //                             <li className="nav-item">
// //                                 <Link className="nav-link active" aria-current="page" to="/" style={{ fontSize: '16px', fontWeight: '500' }}>Home</Link>
// //                             </li>
// //                             <li className="nav-item">
// //                                 <Link className="nav-link" to="/buyPage" style={{ fontSize: '16px', fontWeight: '500' }}>Buy</Link>
// //                             </li>
// //                             <li className="nav-item">
// //                                 <Link className="nav-link" to="/contact" style={{ fontSize: '16px', fontWeight: '500' }}>Contact</Link>
// //                             </li>
// //                             <li className="nav-item">
// //                                 <Link className="nav-link" to="/aboutUs" style={{ fontSize: '16px', fontWeight: '500' }}>About Us</Link>
// //                             </li>

// //                             {userRole === 'user' ? (
// //                                   <li className="custom-nav-item" style={{ position: 'relative', marginRight: '20px' }}>
// //                                   <div 
// //                                       className="d-flex align-items-center" 
// //                                       style={{ cursor: 'pointer' }}
// //                                       onClick={toggleDropdown}
// //                                   >
// //                                       <div className="avatar-circle" style={{
// //                                           width: '40px',
// //                                           height: '40px',
// //                                           borderRadius: '50%',
// //                                           backgroundColor: '#d6ab5e',
// //                                           color: '#122620',
// //                                           display: 'flex',
// //                                           justifyContent: 'center',
// //                                           alignItems: 'center',
// //                                       }}>
// //                                           <span>{userName}</span>
// //                                       </div>
// //                                       <KeyboardArrowDownIcon style={{ color: '#d6ab5e', marginLeft: '5px' }} />
// //                                   </div>
// //                                   {dropdownOpen && (
// //                                       <ul className="custom-dropdown-menu">
// //                                           <li>
// //                                               <button className="dropdown-item" onClick={() => navigate('/user/userProfile')}>Profile</button>
// //                                           </li>
// //                                           <li>
// //                                               <button className="dropdown-item" onClick={() => navigate('/user/userWishlist')}>Wishlist</button>
// //                                           </li>
// //                                           <li>
// //                                               <button className="dropdown-item" onClick={handleLogout}>Logout</button>
// //                                           </li>
// //                                       </ul>
// //                                   )}
// //                               </li>

// //                             ) : (
// //                                 <>
// //                                     <li className="nav-item">
// //                                         <Link className="nav-link" to="/login" style={{ fontSize: '16px', fontWeight: '500' }}>Login</Link>
// //                                     </li>
// //                                     <li className="nav-item">
// //                                         <Link className="nav-link" to="/userRegister" style={{ fontSize: '16px', fontWeight: '500' }}>Register</Link>
// //                                     </li>
// //                                 </>
// //                             )}




// //                         </ul>
// //                     </div>
// //                 </div>
// //             </nav>

// //             <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel" >
// //                 <div className="offcanvas-header d-flex justify-content-between" style={{ backgroundColor: "var( --bs-primary)", color: "#fff" }}>
// //                     <h5 className="offcanvas-title nav-logo" id="offcanvasNavbarLabel"><img src="./assets/images/homePageImg/logo.png" alt="" /></h5>
// //                     <button type="button" className="" data-bs-dismiss="offcanvas" aria-label="Close" style={{ background: "transparent", border: "none" }}><CloseIcon style={{ color: '#fff' }} /></button>
// //                 </div>
// //                 <div className="offcanvas-body">
// //                     <ul className="navbar-nav">
// //                         <li className="nav-item">
// //                             <Link className="nav-link active" aria-current="page" to="/" style={{ fontSize: '16px', fontWeight: '500', fontFamily: 'sans-serif' }}>Home</Link>
// //                         </li>
// //                         <li className="nav-item">
// //                             <Link className="nav-link" to="/buyPage" style={{ fontSize: '16px', fontWeight: '500', fontFamily: 'sans-serif' }}>Buy</Link>
// //                         </li>
// //                         <li className="nav-item">
// //                             <Link className="nav-link" to="/contact" aria-disabled="true" style={{ fontSize: '16px', fontWeight: '500', fontFamily: 'sans-serif' }}>Contact</Link>
// //                         </li>
// //                         <li className="nav-item">
// //                             <Link className="nav-link" to="/aboutUs" aria-disabled="true" style={{ fontSize: '16px', fontWeight: '500', fontFamily: 'sans-serif' }}>About Us</Link>
// //                         </li>

// //                         <li className="nav-item">
// //                             <Link className="nav-link" to="/login" aria-disabled="true" style={{ fontSize: '16px', fontWeight: '500', fontFamily: 'sans-serif' }}>Login</Link>
// //                         </li>
// //                     </ul>

// //                 </div>
// //             </div>
// //         </div>
// //     );
// // };

// // export default Navbar;







// ////////////////////////////////


// import React, { useState, useEffect } from 'react';
// import { Link, useLocation, useNavigate } from 'react-router-dom';
// import '../navbar/Navbar.css';
// import MenuIcon from '@mui/icons-material/Menu';
// import CloseIcon from '@mui/icons-material/Close';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import { Offcanvas } from 'react-bootstrap';
// import LogoutIcon from '@mui/icons-material/Logout';
// import BookmarksIcon from '@mui/icons-material/Bookmarks';
// import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
// import EmailIcon from '@mui/icons-material/Email';
// import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
// import MaleIcon from '@mui/icons-material/Male';

// const Navbar = () => {
//     const [nav, setNavbar] = useState(false);
//     const location = useLocation();
//     const navigate = useNavigate();
//     const [userRole, setUserRole] = useState(null);
//     const [userName, setUserName] = useState("");
//     const [sidebarOpen, setSidebarOpen] = useState(false);
//     const [userDetails, setUserDetails] = useState({});

//     useEffect(() => {
//         // Check the role and user name in local storage when the component mounts
//         const role = localStorage.getItem('role');
//         const user = JSON.parse(localStorage.getItem('user'));
//         setUserRole(role);
//         if (user) {
//             const { firstName, lastName } = user;
//             setUserName(`${firstName[0]}${lastName[0]}`);
//             setUserDetails(user);  // Store full user details

//         }
//     }, []);

//     const changeBackground = () => {
//         if (window.scrollY >= 80) {
//             setNavbar(true);
//         } else {
//             setNavbar(false);
//         }
//     };

//     useEffect(() => {
//         window.addEventListener('scroll', changeBackground);
//         return () => {
//             window.removeEventListener('scroll', changeBackground);
//         };
//     }, []);

//     const handleLogout = () => {
//         localStorage.clear();
//         window.location.reload();
//     };
//     const toggleSidebar = () => {
//         setSidebarOpen(!sidebarOpen);
//     };

//     const disableClassesPaths = ['/buyPage', '/propertyDescription', '/userRegister', '/userWishlist'];
//     const disableClasses = disableClassesPaths.includes(location.pathname);
//     const isBuyPage = location.pathname === '/buyPage';
//     const isPropPage = location.pathname.startsWith('/propertyDescription');
//     const isContactPage = location.pathname === '/contact';
//     const isAboutPage = location.pathname === '/aboutUs';
//     const isRegisterPage = location.pathname === '/userRegister';
//     const user = location.pathname === '/user/userWishlist';

//     return (
//         <div>
//             <nav className={`navbar nav-header navbar-expand-lg 
//                 ${!disableClasses ? (nav ? 'nav active' : '') : ''} 
//                 ${!disableClasses ? 'position-fixed' : ''} 
//                 ${isBuyPage ? 'buyPage-background ' : ''} 
//                 ${isAboutPage ? 'pagecolor ' : ''} 
//                 ${isPropPage ? 'propPage-background' : ''}  
//                 ${isContactPage ? 'contactPage-background' : ''}  
//                 ${isRegisterPage ? 'buyPage-background' : ''}  
//                 ${user ? 'buyPage-background' : ''}  
//                  top-0 w-100 z-3`}>

//                 <div className="container-fluid nav-padding d-flex justify-content-between align-items-center">
//                     <Link className="nav-logo" to="/">
//                         <img src="/assets/images/logo/logo.png" alt="" />
//                     </Link>
//                     <button className="navbar-toggler border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
//                         <span> <MenuIcon style={{ color: '#fff' }} /></span>
//                     </button>
//                     <div className="collapse navbar-collapse justify-content-end">
//                         <ul className="navbar-nav mb-2 mb-lg-0 nav-right-item d-flex align-items-center gap-5 me-4">
//                             <li className="nav-item">
//                                 <Link className="nav-link active" aria-current="page" to="/" style={{ fontSize: '16px', fontWeight: '500' }}>Home</Link>
//                             </li>
//                             <li className="nav-item">
//                                 <Link className="nav-link" to="/buyPage" style={{ fontSize: '16px', fontWeight: '500' }}>Buy</Link>
//                             </li>
//                             <li className="nav-item">
//                                 <Link className="nav-link" to="/contact" style={{ fontSize: '16px', fontWeight: '500' }}>Contact</Link>
//                             </li>
//                             <li className="nav-item">
//                                 <Link className="nav-link" to="/aboutUs" style={{ fontSize: '16px', fontWeight: '500' }}>About Us</Link>
//                             </li>

//                             {userRole === 'user' ? (
//                                 <li className="custom-nav-item" style={{ position: 'relative', marginRight: '20px' }}>
//                                     <div
//                                         className="d-flex align-items-center"
//                                         style={{ cursor: 'pointer' }}
//                                         onClick={toggleSidebar}
//                                     >
//                                         <div className="avatar-circle" style={{
//                                             width: '40px',
//                                             height: '40px',
//                                             borderRadius: '50%',
//                                             backgroundColor: '#d6ab5e',
//                                             color: '#122620',
//                                             display: 'flex',
//                                             justifyContent: 'center',
//                                             alignItems: 'center',
//                                         }}>
//                                             <span>{userName}</span>
//                                         </div>
//                                         <KeyboardArrowDownIcon style={{ color: '#d6ab5e', marginLeft: '5px' }} />
//                                     </div>
//                                 </li>

//                             ) : (
//                                 <>
//                                     <li className="nav-item">
//                                         <Link className="nav-link" to="/login" style={{ fontSize: '16px', fontWeight: '500' }}>Login</Link>
//                                     </li>
//                                     <li className="nav-item">
//                                         <Link className="nav-link" to="/userRegister" style={{ fontSize: '16px', fontWeight: '500' }}>Register</Link>
//                                     </li>
//                                 </>
//                             )}




//                         </ul>
//                     </div>
//                 </div>
//             </nav>



//             <Offcanvas show={sidebarOpen} onHide={toggleSidebar} placement="end" style={{ width: '300px' }}>
//                 <Offcanvas.Header style={{ backgroundColor: 'var(--bs-primary)' }} closeButton >

//                     <Offcanvas.Title>
//                         <div className="avatar-circle" style={{
//                             width: '60px',
//                             height: '60px',
//                             borderRadius: '50%',
//                             backgroundColor: '#d6ab5e',
//                             color: '#122620',
//                             display: 'flex',
//                             justifyContent: 'center',
//                             alignItems: 'center',
//                             margin: '0 auto'
//                         }}>
//                             <span>{userName}</span>
//                         </div>
//                     </Offcanvas.Title>
//                 </Offcanvas.Header>
//                 <Offcanvas.Body style={{ background: "var(--bs-secondary)" }}>
//                     <div style={{

//                     }}>
//                         <h4 className='text-center'>{userDetails.firstName} {userDetails.lastName}</h4>

//                         <div>   <h6 style={{ marginBottom: "0px" }}>Email: </h6>  <p><EmailIcon className="me-1 profile-icon " />{userDetails.email}</p></div>

//                         <div> <h6 style={{ marginBottom: "0px" }}>Age:</h6>
//                             <p><CalendarTodayIcon className="me-1 profile-icon " />{userDetails.age}</p>
//                         </div>
//                         <div>
//                             <h6 style={{ marginBottom: "0px" }}> Gender:</h6>   <p> <MaleIcon className="me-1 profile-icon " />{userDetails.gender}</p></div>
//                         <div>
//                             <h6 className='' style={{ marginBottom: "0px" }}>Phone:</h6> <p><LocalPhoneIcon className="me-1 profile-icon " />
//                                 {userDetails.phoneNumber}</p>
//                         </div>
//                     </div>
//                     <hr />
//                     <ul className="list-unstyled">
//                         <li className='my-2'>
//                             <button className="dropdown-item" onClick={() => navigate('/user/userWishlist')} style={{
//                                 // background: "var(--bs-accent)",
//                                 // width: "75px",
//                                 // padding: "5px 8px",
//                                 // borderRadius: "5px",

//                                 // display: "flex",
//                                 // justifyContent: "center",
//                                 // color: "#fff",
//                                 // marginBottom: "10px"
//                             }}><BookmarksIcon style={{ color: "green", marginRight: "9px", fontSize: "18px" }} />Wishlist</button>
//                         </li>
//                         <li className='my-2'>
//                             <button className="dropdown-item" onClick={handleLogout}
//                                 style={{
//                                     // background: "#931313",
//                                     // width: "75px",
//                                     // padding: "5px 8px",
//                                     // borderRadius: "5px",
//                                     // color: "#fff",
//                                     // display: "flex",
//                                     // justifyContent: "center"
//                                 }}
//                             ><LogoutIcon style={{ color: "red", marginRight: "8px", fontSize: "18px" }} /> Logout</button>
//                         </li>
//                     </ul>
//                 </Offcanvas.Body>
//             </Offcanvas>



//             {/* Offcanvas for mobile navigation */}
//             <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
//                 <div className="offcanvas-header d-flex justify-content-between" style={{ backgroundColor: "var(--bs-primary)", color: "#fff" }}>
//                     <h5 className="offcanvas-title nav-logo" id="offcanvasNavbarLabel">
//                         <img src="/assets/images/logo/logo.png" alt="" />
//                     </h5>
//                     <button type="button" className="" data-bs-dismiss="offcanvas" aria-label="Close" style={{ background: "transparent", border: "none" }}>
//                         <CloseIcon style={{ color: '#fff' }} />
//                     </button>
//                 </div>
//                 <div className="offcanvas-body">
//                     <ul className="navbar-nav">
//                         <li className="nav-item">
//                             <Link className="nav-link active" aria-current="page" to="/" style={{ fontSize: '16px', fontWeight: '500', fontFamily: 'sans-serif' }}>Home</Link>
//                         </li>
//                         <li className="nav-item">
//                             <Link className="nav-link" to="/buyPage" style={{ fontSize: '16px', fontWeight: '500', fontFamily: 'sans-serif' }}>Buy</Link>
//                         </li>
//                         <li className="nav-item">
//                             <Link className="nav-link" to="/contact" aria-disabled="true" style={{ fontSize: '16px', fontWeight: '500', fontFamily: 'sans-serif' }}>Contact</Link>
//                         </li>
//                         <li className="nav-item">
//                             <Link className="nav-link" to="/aboutUs" aria-disabled="true" style={{ fontSize: '16px', fontWeight: '500', fontFamily: 'sans-serif' }}>About Us</Link>
//                         </li>
//                         {userRole === 'user' && (
//                             <li className="nav-item">
//                                 <div
//                                     className="d-flex align-items-center"
//                                     style={{ cursor: 'pointer', marginTop: '20px' }}
//                                     onClick={toggleSidebar}
//                                 >
//                                     <div className="avatar-circle" style={{
//                                         width: '40px',
//                                         height: '40px',
//                                         borderRadius: '50%',
//                                         backgroundColor: '#d6ab5e',
//                                         color: '#122620',
//                                         display: 'flex',
//                                         justifyContent: 'center',
//                                         alignItems: 'center',
//                                     }}>
//                                         <span>{userName}</span>
//                                     </div>
//                                     <span style={{ marginLeft: '10px', color: '#122620', fontWeight: '500' }}>Profile</span>
//                                 </div>
//                             </li>
//                         )}
//                         {userRole !== 'user' && (
//                             <>
//                                 <li className="nav-item">
//                                     <Link className="nav-link" to="/login" style={{ fontSize: '16px', fontWeight: '500', fontFamily: 'sans-serif' }}>Login</Link>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link className="nav-link" to="/userRegister" style={{ fontSize: '16px', fontWeight: '500', fontFamily: 'sans-serif' }}>Register</Link>
//                                 </li>
//                             </>
//                         )}
//                     </ul>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Navbar;










// import React, { useState, useEffect } from 'react';
// import { Link, useLocation, useNavigate } from 'react-router-dom';
// import '../navbar/Navbar.css';
// import MenuIcon from '@mui/icons-material/Menu';
// import CloseIcon from '@mui/icons-material/Close';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// const Navbar = () => {
//     const [nav, setNavbar] = useState(false);
//     const location = useLocation();
//     const navigate = useNavigate();
//     const [userRole, setUserRole] = useState(null);
//     const [userName, setUserName] = useState("");
//     const [dropdownOpen, setDropdownOpen] = useState(false);

//     useEffect(() => {
//         // Check the role and user name in local storage when the component mounts
//         const role = localStorage.getItem('role');
//         const user = JSON.parse(localStorage.getItem('user'));
//         setUserRole(role);
//         if (user) {
//             const { firstName, lastName } = user;
//             setUserName(`${firstName[0]}${lastName[0]}`);
//         }
//     }, []);

//     const changeBackground = () => {
//         if (window.scrollY >= 80) {
//             setNavbar(true);
//         } else {
//             setNavbar(false);
//         }
//     };

//     useEffect(() => {
//         window.addEventListener('scroll', changeBackground);
//         return () => {
//             window.removeEventListener('scroll', changeBackground);
//         };
//     }, []);

//     const handleLogout = () => {
//         localStorage.clear();
//         window.location.reload();
//     };
//     const toggleDropdown = () => {
//         setDropdownOpen(!dropdownOpen);
//     };

//     const disableClassesPaths = ['/buyPage', '/propertyDescription', '/userRegister','/userWishlist'];
//     const disableClasses = disableClassesPaths.includes(location.pathname);
//     const isBuyPage = location.pathname === '/buyPage';
//     const isPropPage = location.pathname.startsWith('/propertyDescription');
//     const isContactPage = location.pathname === '/contact';
//     const isAboutPage = location.pathname === '/aboutUs';
//     const isRegisterPage = location.pathname === '/userRegister';
//     const user = location.pathname === '/user/userWishlist';

//     return (
//         <div>
//             <nav className={`navbar nav-header navbar-expand-lg 
//                 ${!disableClasses ? (nav ? 'nav active' : '') : ''} 
//                 ${!disableClasses ? 'position-fixed' : ''} 
//                 ${isBuyPage ? 'buyPage-background ' : ''} 
//                 ${isAboutPage ? 'pagecolor ' : ''} 
//                 ${isPropPage ? 'propPage-background' : ''}  
//                 ${isContactPage ? 'contactPage-background' : ''}  
//                 ${isRegisterPage ? 'buyPage-background' : ''}  
//                 ${user ? 'buyPage-background' : ''}  
//                  top-0 w-100 z-3`}>

//                 <div className="container-fluid nav-padding d-flex justify-content-between align-items-center">
//                     <Link className="nav-logo" to="/">
//                         <img src="/assets/images/logo/logo.png" alt="" />
//                     </Link>
//                     <button className="navbar-toggler border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
//                         <span> <MenuIcon style={{ color: '#fff' }} /></span>
//                     </button>
//                     <div className="collapse navbar-collapse justify-content-end">
//                         <ul className="navbar-nav mb-2 mb-lg-0 nav-right-item d-flex align-items-center gap-5 me-4">
//                             <li className="nav-item">
//                                 <Link className="nav-link active" aria-current="page" to="/" style={{ fontSize: '16px', fontWeight: '500' }}>Home</Link>
//                             </li>
//                             <li className="nav-item">
//                                 <Link className="nav-link" to="/buyPage" style={{ fontSize: '16px', fontWeight: '500' }}>Buy</Link>
//                             </li>
//                             <li className="nav-item">
//                                 <Link className="nav-link" to="/contact" style={{ fontSize: '16px', fontWeight: '500' }}>Contact</Link>
//                             </li>
//                             <li className="nav-item">
//                                 <Link className="nav-link" to="/aboutUs" style={{ fontSize: '16px', fontWeight: '500' }}>About Us</Link>
//                             </li>

//                             {userRole === 'user' ? (
//                                   <li className="custom-nav-item" style={{ position: 'relative', marginRight: '20px' }}>
//                                   <div 
//                                       className="d-flex align-items-center" 
//                                       style={{ cursor: 'pointer' }}
//                                       onClick={toggleDropdown}
//                                   >
//                                       <div className="avatar-circle" style={{
//                                           width: '40px',
//                                           height: '40px',
//                                           borderRadius: '50%',
//                                           backgroundColor: '#d6ab5e',
//                                           color: '#122620',
//                                           display: 'flex',
//                                           justifyContent: 'center',
//                                           alignItems: 'center',
//                                       }}>
//                                           <span>{userName}</span>
//                                       </div>
//                                       <KeyboardArrowDownIcon style={{ color: '#d6ab5e', marginLeft: '5px' }} />
//                                   </div>
//                                   {dropdownOpen && (
//                                       <ul className="custom-dropdown-menu">
//                                           <li>
//                                               <button className="dropdown-item" onClick={() => navigate('/user/userProfile')}>Profile</button>
//                                           </li>
//                                           <li>
//                                               <button className="dropdown-item" onClick={() => navigate('/user/userWishlist')}>Wishlist</button>
//                                           </li>
//                                           <li>
//                                               <button className="dropdown-item" onClick={handleLogout}>Logout</button>
//                                           </li>
//                                       </ul>
//                                   )}
//                               </li>

//                             ) : (
//                                 <>
//                                     <li className="nav-item">
//                                         <Link className="nav-link" to="/login" style={{ fontSize: '16px', fontWeight: '500' }}>Login</Link>
//                                     </li>
//                                     <li className="nav-item">
//                                         <Link className="nav-link" to="/userRegister" style={{ fontSize: '16px', fontWeight: '500' }}>Register</Link>
//                                     </li>
//                                 </>
//                             )}




//                         </ul>
//                     </div>
//                 </div>
//             </nav>

//             <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel" >
//                 <div className="offcanvas-header d-flex justify-content-between" style={{ backgroundColor: "var( --bs-primary)", color: "#fff" }}>
//                     <h5 className="offcanvas-title nav-logo" id="offcanvasNavbarLabel"><img src="./assets/images/homePageImg/logo.png" alt="" /></h5>
//                     <button type="button" className="" data-bs-dismiss="offcanvas" aria-label="Close" style={{ background: "transparent", border: "none" }}><CloseIcon style={{ color: '#fff' }} /></button>
//                 </div>
//                 <div className="offcanvas-body">
//                     <ul className="navbar-nav">
//                         <li className="nav-item">
//                             <Link className="nav-link active" aria-current="page" to="/" style={{ fontSize: '16px', fontWeight: '500', fontFamily: 'sans-serif' }}>Home</Link>
//                         </li>
//                         <li className="nav-item">
//                             <Link className="nav-link" to="/buyPage" style={{ fontSize: '16px', fontWeight: '500', fontFamily: 'sans-serif' }}>Buy</Link>
//                         </li>
//                         <li className="nav-item">
//                             <Link className="nav-link" to="/contact" aria-disabled="true" style={{ fontSize: '16px', fontWeight: '500', fontFamily: 'sans-serif' }}>Contact</Link>
//                         </li>
//                         <li className="nav-item">
//                             <Link className="nav-link" to="/aboutUs" aria-disabled="true" style={{ fontSize: '16px', fontWeight: '500', fontFamily: 'sans-serif' }}>About Us</Link>
//                         </li>

//                         <li className="nav-item">
//                             <Link className="nav-link" to="/login" aria-disabled="true" style={{ fontSize: '16px', fontWeight: '500', fontFamily: 'sans-serif' }}>Login</Link>
//                         </li>
//                     </ul>

//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Navbar;







////////////////////////////////


import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../navbar/Navbar.css';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Offcanvas } from 'react-bootstrap';
import LogoutIcon from '@mui/icons-material/Logout';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MaleIcon from '@mui/icons-material/Male';
import ApiService from '../../../../config/api';
import SearchIcon from '@mui/icons-material/Search';

const Navbar = () => {
    const [nav, setNavbar] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [userRole, setUserRole] = useState(null);
    const [userName, setUserName] = useState("");
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [userDetails, setUserDetails] = useState({});
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        // Check the role and user name in local storage when the component mounts
        const role = localStorage.getItem('role');
        const user = JSON.parse(localStorage.getItem('user'));
        setUserRole(role);
        if (user) {
            const { firstName, lastName } = user;
            setUserName(`${firstName[0]}${lastName[0]}`);
            setUserDetails(user);  // Store full user details

        }
    }, []);

    const handleSearch = async () => {
        try {
            const response = await ApiService.get(`/admin/search`, {
                params: {
                    searchValue: searchQuery,
                },
            });
            console.log("Search Results:", response.data.data);
            navigate("/buyPage", { state: { searchResults: response.data.data } });
        } catch (error) {
            console.error("Error searching:", error);
        }
    };


    const changeBackground = () => {
        if (window.scrollY >= 80) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeBackground);
        return () => {
            window.removeEventListener('scroll', changeBackground);
        };
    }, []);

    const handleLogout = () => {
        localStorage.clear();
        window.location.reload();
    };
    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const disableClassesPaths = ['/buyPage', '/propertyDescription', '/userRegister', '/userWishlist'];
    const disableClasses = disableClassesPaths.includes(location.pathname);
    const isBuyPage = location.pathname === '/buyPage';
    const isPropPage = location.pathname.startsWith('/propertyDescription');
    const isContactPage = location.pathname === '/contact';
    const isAboutPage = location.pathname === '/aboutUs';
    const isRegisterPage = location.pathname === '/userRegister';
    const user = location.pathname === '/user/userWishlist';

    const navigateToBuy = ()=>{
        navigate(`/buyPage?search=${searchQuery}`)
    }

    return (
        <div className='z-3'>
            <nav className={`navbar nav-header navbar-expand-lg 
                ${!disableClasses ? (nav ? 'nav active' : '') : ''} 
                ${!disableClasses ? 'position-fixed' : ''} 
                ${isBuyPage ? 'buyPage-background ' : ''} 
                ${isAboutPage ? 'pagecolor ' : ''} 
                ${isPropPage ? 'propPage-background' : ''}  
                ${isContactPage ? 'contactPage-background' : ''}  
                ${isRegisterPage ? 'buyPage-background' : ''}  
                ${user ? 'buyPage-background' : ''}  
                 top-0 w-100 z-3`}>

                <div className="container-fluid nav-padding d-flex justify-content-between align-items-center">
                    <Link className="nav-logo" to="/">
                        <img src="/assets/images/logo/logo.png" alt="Logo" />
                    </Link>
                    <button className="navbar-toggler border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                        <span> <MenuIcon style={{ color: 'var(--bs-accent)' }} /></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end">
                        <ul className="navbar-nav mb-2 mb-lg-0 nav-right-item d-flex align-items-center gap-5 me-4">
                            <li className="nav-item d-none d-lg-flex align-items-center position-relative">
                                <input
                                    className="form-control search-input-navbar"
                                    type="text"
                                    placeholder="Search any property..."
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    onKeyDown={(e) => e.key === 'Enter' && navigateToBuy()} // Trigger search on Enter
                                    style={{ width: '300px', paddingRight: '40px', backgroundColor: 'var(--bs-secondary)' }}
                                />
                                {searchQuery && (
                                    <button
                                        className="btn clear-btn-navbar position-absolute"
                                        onClick={navigateToBuy}
                                        style={{ right: '30px', top: '50%', transform: 'translateY(-50%)', fontSize: '32px' }}
                                    >
                                        &times;
                                    </button>
                                )}
                              
                                <button
                                    className="btn search-btn-navbar ms-2"
                                    onClick={navigateToBuy}
                                    style={{ backgroundColor: 'transparent', border: 'none' }}
                                >
                                    <SearchIcon style={{ color: 'var(--bs-accent)' }} />  {/* or <i className="fas fa-search"></i> */}
                                </button>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/buyPage" style={{ fontSize: '16px', fontWeight: '500' }}>Buy</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/contact" style={{ fontSize: '16px', fontWeight: '500' }}>Contact</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/aboutUs" style={{ fontSize: '16px', fontWeight: '500' }}>About Us</Link>
                            </li>

                            {userRole === 'user' ? (
                                <li className="custom-nav-item" style={{ position: 'relative', marginRight: '20px' }}>
                                    <div
                                        className="d-flex align-items-center"
                                        style={{ cursor: 'pointer' }}
                                        onClick={toggleSidebar}
                                    >
                                        <div className="avatar-circle" style={{
                                            width: '40px',
                                            height: '40px',
                                            borderRadius: '50%',
                                            backgroundColor: '#d6ab5e',
                                            color: '#122620',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}>
                                            <span>{userName}</span>
                                        </div>
                                        <KeyboardArrowDownIcon style={{ color: '#d6ab5e', marginLeft: '5px' }} />
                                    </div>
                                </li>
                            ) : (
                                <>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/login" style={{ fontSize: '16px', fontWeight: '500' }}>Login</Link>
                                    </li>
                                </>
                            )}
                        </ul>
                    </div>

                </div>



            </nav>



            <Offcanvas show={sidebarOpen} onHide={toggleSidebar} placement="end" style={{ width: '300px' }}>
                <Offcanvas.Header style={{ backgroundColor: 'var(--bs-primary)' }} closeButton >

                    <Offcanvas.Title>
                        <div className="avatar-circle" style={{
                            width: '60px',
                            height: '60px',
                            borderRadius: '50%',
                            backgroundColor: '#d6ab5e',
                            color: '#122620',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '0 auto'
                        }}>
                            <span>{userName}</span>
                        </div>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body style={{ background: "var(--bs-secondary)" }}>
                    <div style={{

                    }}>
                        <h4 className='text-center'>{userDetails.firstName} {userDetails.lastName}</h4>

                        <div>   <h6 style={{ marginBottom: "0px" }}>Email: </h6>  <p><EmailIcon className="me-1 profile-icon " />{userDetails.email}</p></div>

                        <div> <h6 style={{ marginBottom: "0px" }}>Age:</h6>
                            <p><CalendarTodayIcon className="me-1 profile-icon " />{userDetails.age}</p>
                        </div>
                        <div>
                            <h6 style={{ marginBottom: "0px" }}> Gender:</h6>   <p> <MaleIcon className="me-1 profile-icon " />{userDetails.gender}</p></div>
                        <div>
                            <h6 className='' style={{ marginBottom: "0px" }}>Phone:</h6> <p><LocalPhoneIcon className="me-1 profile-icon " />
                                {userDetails.phoneNumber}</p>
                        </div>
                    </div>
                    <hr />
                    <ul className="list-unstyled">
                        <li className='my-2'>
                            <button className="dropdown-item" onClick={() => navigate('/user/userWishlist')} style={{
                                // background: "var(--bs-accent)",
                                // width: "75px",
                                // padding: "5px 8px",
                                // borderRadius: "5px",

                                // display: "flex",
                                // justifyContent: "center",
                                // color: "#fff",
                                // marginBottom: "10px"
                            }}><BookmarksIcon style={{ color: "green", marginRight: "9px", fontSize: "18px" }} />Wishlist</button>
                        </li>
                        <li className='my-2'>
                            <button className="dropdown-item" onClick={handleLogout}
                                style={{
                                    // background: "#931313",
                                    // width: "75px",
                                    // padding: "5px 8px",
                                    // borderRadius: "5px",
                                    // color: "#fff",
                                    // display: "flex",
                                    // justifyContent: "center"
                                }}
                            ><LogoutIcon style={{ color: "red", marginRight: "8px", fontSize: "18px" }} /> Logout</button>
                        </li>
                    </ul>
                </Offcanvas.Body>
            </Offcanvas>



            {/* Offcanvas for mobile navigation */}
            <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                <div className="offcanvas-header d-flex justify-content-between" style={{ backgroundColor: "var(--bs-primary)", color: "#fff" }}>
                    <h5 className="offcanvas-title nav-logo" id="offcanvasNavbarLabel">
                        <img src="/assets/images/logo/logo.png" alt="" />
                    </h5>
                    <button type="button" className="" data-bs-dismiss="offcanvas" aria-label="Close" style={{ background: "transparent", border: "none" }}>
                        <CloseIcon style={{ color: '#fff' }} />
                    </button>
                </div>
                <div className="offcanvas-body" style={{ backgroundColor: 'var(--bs-secondary)' }}>
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link className="nav-link active" aria-current="page" to="/" style={{ fontSize: '16px', fontWeight: '500', fontFamily: 'sans-serif' }}>Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/buyPage" style={{ fontSize: '16px', fontWeight: '500', fontFamily: 'sans-serif' }}>Buy</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/contact" aria-disabled="true" style={{ fontSize: '16px', fontWeight: '500', fontFamily: 'sans-serif' }}>Contact</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/aboutUs" aria-disabled="true" style={{ fontSize: '16px', fontWeight: '500', fontFamily: 'sans-serif' }}>About Us</Link>
                        </li>
                        {userRole === 'user' && (
                            <li className="nav-item">
                                <div
                                    className="d-flex align-items-center"
                                    style={{ cursor: 'pointer', marginTop: '20px' }}
                                    onClick={toggleSidebar}
                                >
                                    <div className="avatar-circle" style={{
                                        width: '40px',
                                        height: '40px',
                                        borderRadius: '50%',
                                        backgroundColor: '#d6ab5e',
                                        color: '#122620',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                        <span>{userName}</span>
                                    </div>
                                    <span style={{ marginLeft: '10px', color: '#122620', fontWeight: '500' }}>Profile</span>
                                </div>
                            </li>
                        )}
                        {userRole !== 'user' && (
                            <>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/login" style={{ fontSize: '16px', fontWeight: '500', fontFamily: 'sans-serif' }}>Login</Link>
                                </li>
                                {/* <li className="nav-item">
                                    <Link className="nav-link" to="/userRegister" style={{ fontSize: '16px', fontWeight: '500', fontFamily: 'sans-serif' }}>Register</Link>
                                </li> */}
                            </>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
