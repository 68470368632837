import React, { useState } from 'react';
import { Container, Grid, Typography, Divider, Box, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import GoogleIcon from '@mui/icons-material/Google';
import InstagramIcon from '@mui/icons-material/Instagram';
import CareerModal from '../careerModal/CareerModal';
import { Link } from 'react-router-dom';
import logo from '../../../../assets/font/icon.png';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

const Footer = () => {

    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    return (
        <Box sx={{ bgcolor: 'var(--bs-primary)', color: 'white', pt: 4, pb: 3, color: 'var(--bs-accent)' }}>
            <Container maxWidth="lg">
                <Grid container spacing={4}>

                    <Grid item xs={12} sm={6} md={3}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: { xs: 'center', sm: 'flex-start' },
                                alignItems: 'center',
                                height: '100%'
                            }}
                        >
                            <img src={logo} alt="Logo" style={{ width: '250px', height: '120px' }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} style={{ cursor: 'pointer' }}>
                        <Typography variant="h6" gutterBottom>
                            Quick Links
                        </Typography>
                        <Box mb={1}>
                            <Link to="/buyPage" style={{ textDecoration: 'none', color: '#D6AD60' }}>
                                <Typography>Our Story</Typography>
                            </Link>
                        </Box>
                        <Box mb={1}>
                            <Link to="/termsAndConditions" style={{ color: 'inherit', textDecoration: 'none' }}>
                                Terms and Conditions
                            </Link>
                        </Box>
                        <Box mb={1}>
                            <Typography onClick={handleOpenModal}>
                                Careers
                            </Typography>
                        </Box>
                        <Box>
                            <Link style={{ textDecoration: 'none', color: '#D6AD60' }} to="/contact">
                                <Typography>Contact</Typography>
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="h6" gutterBottom>
                            Address
                        </Typography>
                        <Box mb={2}>
                            <Typography style={{ color: 'inherit' }}>Bhopal , Madhya Pradesh
                            </Typography>
                        </Box>
                        <Box mb={1}>
                            <Typography style={{ color: 'inherit', display: 'flex', alignItems: 'center' }}>
                                <EmailIcon style={{ marginRight: '5px' }} /> info@eleganzaestatesolutions.com
                            </Typography>
                        </Box>
                        <Box mb={1}>
                            <Typography style={{ color: 'inherit', display: 'flex', alignItems: 'center' }}>
                                <PhoneIcon style={{ marginRight: '5px' }} /> +91 9993156333
                            </Typography>
                        </Box>
                        <Box>
                            <Typography style={{ color: 'inherit', display: 'flex', alignItems: 'center' }}>
                                <PhoneIcon style={{ marginRight: '5px' }} /> +91 9993015333
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6" gutterBottom>
                            Working Hours
                        </Typography>
                        <Box>
                            <Typography style={{ color: 'inherit' }}>
                                Monday to Saturday : <br/>9:00 AM - 6:00 PM
                            </Typography>
                        </Box>
                        <Box>
                            <Typography style={{ color: 'inherit' }}>
                                Sunday: Closed
                            </Typography>
                        </Box>
                    </Grid>



                </Grid>
                <Divider sx={{ my: 2, bgcolor: 'white' }} />
                <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">

                    <Typography variant="body2" sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                        © 2024 Eleganza Group. All Rights Reserved.
                    </Typography>
                    <Box display="flex" justifyContent="center">
                        <IconButton href="https://www.facebook.com/share/1Fr8JJ6evq/?mibextid=LQQJ4d" color="inherit"
                            target="_blank"
                            rel="noopener noreferrer">
                            <FacebookIcon />
                        </IconButton>
                        <IconButton
                            href="https://www.instagram.com/eleganzaestatesolutions?igsh=MXd4bHl3azJuNW03cg%3D%3D&utm_source=qr"
                            color="inherit"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <InstagramIcon />
                        </IconButton>

                    </Box>
                </Box>
            </Container>
            <CareerModal show={showModal} handleClose={handleCloseModal} />

        </Box>
    );
};

export default Footer;