import React, { useState } from "react";
import "./AboutUs.css";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";

const AboutUs = () => {
  const [activeTab, setActiveTab] = useState("Rent");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <Navbar />

      <div className="pagecolor mt-3">
        <div className="heroes min-vh-100 d-flex align-items-center">
          <div className="container">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-12 col-md-12 mb-3 d-flex justify-content-center">
                    <img
                      src="\assets\images\buyPageImg\butterfly-house-martis-camp-united-states-2-1024x683.webp"
                      alt="Top Image"
                      className="img-fluid custom-image "
                      style={{
                        width: "600px",
                        height: "auto",
                        borderRadius: "30px",
                        marginTop: "50px",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 hero-text-about d-flex justify-content-center align-items-center">
                <div>
                  <h3
                    className="m-5"
                    style={{
                      color: "var(--bs-accent)",
                      fontFamily: "Bahnschrift SemiBold",
                      fontWeight: "bold",
                    }}
                  >
                    About Us
                  </h3>
                  <p
                    className="contant"
                    style={{
                      color: " var(--bs-secondary)",
                      fontSize: "16px",
                    }}
                  >
                    Eleganza Estate Solutions was founded to revolutionise the real estate experience for buyers. At Eleganza Estate Solutions, we believe that luxury real estate should be accessible, transparent, and effortless. That’s why we’ve redefined the property-buying experience with our <span style={{ fontWeight: 'bold' }}>Zero Brokerage Policy</span>, ensuring buyers pay no commission fees.
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className="container reviews">
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-md-12">
              <div className="about-review-box">
                <h3
                  className="mb-2  contantheading"
                  style={{
                    color: "var(--bs-accent)",
                    fontFamily: "Bahnschrift SemiBold",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Who We Are
                </h3>
                <p
                  className="text-center contant"
                  style={{
                    color: "var(--bs-secondary)",
                    fontSize: "16px",
                  }}
                >
                  Eleganza Estate Solutions is a premier real estate platform dedicated to showcasing{" "}
                  <span style={{ fontWeight: "bold" }}>timeless elegance</span> through a curated collection of high-value properties. We connect buyers with premium homes and investment opportunities in and around Bhopal, delivering unmatched expertise and seamless service.
                </p>
              </div>
            </div>
          </div>

          {/* Our Founders Section */}
          <div className="row justify-content-center">
            <h3
              className="contantheading"
              style={{
                color: "var(--bs-accent)",
                fontFamily: "Bahnschrift, sans-serif",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              Our Founders
            </h3>
            <p
              className="text-center contant"
              style={{
                color: "var(--bs-secondary)",
                fontSize: "1rem",
                // marginBottom: "2rem", 
              }}
            >
              Eleganza was established by three visionaries who share a passion for innovation and excellence in real estate:
            </p>

            <div className="col-lg-4 col-md-6 mb-4">
              <div
                className="card shadow-lg border-0"
                style={{
                  width: "100%",
                  textAlign: "center",
                  borderRadius: "20px",
                  transition: "transform 0.3s ease",
                }}
              >
                <div
                  className="card-body"
                  style={{
                    height: "150px", // Set a fixed height
                    display: "flex",
                    flexDirection: "column",
                    padding: "1rem",
                    backgroundColor: '#F4EBD0',
                    borderRadius: '20px'
                  }}
                >
                  <h5 className="card-title" style={{ fontWeight: "700", fontSize: "1.25rem" }}>
                    Siraj Khan
                  </h5>
                  <p
                    className="card-text1"
                    style={{
                      fontSize: ".8rem",
                      color: "#6B7280",
                      lineHeight: "1.4",
                      overflow: "visible",
                      marginTop: 5,
                    }}
                  >
                    With an M.Tech in Construction Technology Management, Siraj is the founder of Ideal Construction and Estetico Architecture Studio, bringing expertise in construction and project management to Eleganza.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <div
                className="card shadow-lg border-0"
                style={{
                  width: "100%",
                  textAlign: "center",
                  borderRadius: "20px",
                  transition: "transform 0.3s ease",
                }}
              >
                <div
                  className="card-body"
                  style={{
                    height: "150px", // Set a fixed height
                    display: "flex",
                    flexDirection: "column",
                    padding: "1rem",
                    backgroundColor: '#F4EBD0',
                    borderRadius: '20px'
                  }}
                >
                  <h5 className="card-title" style={{ fontWeight: "700", fontSize: "1.25rem" }}>
                    Saad Shahaab
                  </h5>
                  <p
                    className="card-text1"
                    style={{
                      fontSize: ".8rem",
                      color: "#6B7280",
                      lineHeight: "1.4",
                      wordWrap: "break-word",
                      maxHeight: "none",
                      overflow: "visible",
                      marginTop: 5
                    }}
                  >
                    A Bachelor of Architecture graduate from NIT Bhopal and founder of Estetico Architecture Studio, Saad ensures each property embodies superior design and functionality.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <div
                className="card shadow-lg border-0"
                style={{
                  width: "100%",
                  textAlign: "center",
                  borderRadius: "20px",
                  transition: "transform 0.3s ease",
                }}
              >
                <div
                  className="card-body"
                  style={{
                    height: "150px", // Set a fixed height
                    display: "flex",
                    flexDirection: "column",
                    padding: "1rem",
                    backgroundColor: '#F4EBD0',
                    borderRadius: '20px'
                  }}
                >
                  <h5 className="card-title" style={{ fontWeight: "700", fontSize: "1.25rem" }}>
                    Suhail Ahmad
                  </h5>
                  <p
                    className="card-text1"
                    style={{
                      fontSize: ".8rem",
                      color: "#6B7280",
                      lineHeight: "1.4",
                      wordWrap: "break-word",
                      maxHeight: "none",
                      overflow: "visible",
                      marginTop: 5,
                    }}
                  >
                    A seasoned entrepreneur and a well-known name in Bhopal’s real estate market, Suhail’s extensive network and market knowledge are the backbone of our success.
                  </p>
                </div>
              </div>
            </div>
            <p
              className="text-center contant"
              style={{
                color: "var(--bs-secondary)",
                fontSize: "1rem",
                lineHeight: "1.8",
                marginTop: "2rem", // Spacing above the text
                marginBottom: "3rem", // Spacing below the paragraph
              }}
            >
              Together, they recognized an opportunity to offer premium properties without the burden of high brokerage fees. Eleganza was their solution: a platform providing exclusive, high-end properties with no hidden costs, ensuring a seamless and transparent experience for buyers.
            </p>
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 hero-text-about" style={{ alignContent: "center" }}>
                <div
                  style={{
                    color: "var(--bs-secondary)",
                    fontSize: "16px",
                  }}
                >
                  <h5 style={{ fontWeight: "bold", marginTop: "20px", color: "var(--bs-accent)" }}>Our Services and Reach</h5>
                  <p style={{ fontSize: '16px' }}>
                    We handpick luxury apartments, elegant villas, spacious plots, and high-value commercial properties in and around Bhopal. Our curated selection caters to those who value timeless elegance and smart investments. While Bhopal is where we started, our goal is to establish Eleganza as a global leader in the premium real estate market.
                  </p>

                  <h5 style={{ fontWeight: "bold", marginTop: "20px", color: "var(--bs-accent)" }}>Zero Brokerage, Maximum Value</h5>
                  <p style={{ fontSize: '16px' }}>
                    Eleganza’s Zero Brokerage Policy ensures buyers pay no extra fees. This commitment to transparency makes the property-buying journey smoother, more accessible, and stress-free. No fees, no hidden costs—just exceptional service and premium properties.
                  </p>

                  <h5 style={{ fontWeight: "bold", marginTop: "20px", color: "var(--bs-accent)" }}>Eleganza’s Commitment to Diversity</h5>
                  <p style={{ fontSize: '16px' }}>
                    Eleganza Estate Solutions isn’t limited to residential spaces. We also specialise in prime plots and premium commercial properties, ensuring our buyers have access to a wide variety of high-value real estate options.
                  </p>

                  <h5 style={{ fontWeight: "bold", marginTop: "20px", color: "var(--bs-accent)" }}>Looking Ahead</h5>
                  <p style={{ fontSize: '16px' }}>
                    Eleganza isn’t just about real estate; it’s about redefining how buyers access premium properties. Whether you’re a homebuyer or an investor, we’re here to help you find value and elegance, all under one roof.
                  </p>

                  <blockquote className="text-center" style={{ fontSize: '16px', fontStyle: 'italic' ,marginTop:'30px'}}>
                    "More than a real estate firm, it’s your gateway to timeless elegance and timeless investment."
                  </blockquote>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <Footer />
    </>
  );
};

export default AboutUs;