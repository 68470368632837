import React, { useEffect, useState } from "react";
import {
  Card, CardContent, CardMedia, Grid, Typography, Box, Button, TextField, Slider, Modal, IconButton
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import ApiService from "../../../config/api";
import BedOutlinedIcon from '@mui/icons-material/BedOutlined'; import BathtubOutlinedIcon from '@mui/icons-material/BathtubOutlined'; import SquareFootIcon from '@mui/icons-material/SquareFoot';
import "./BuyPage.css";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import PropertyInquiryModal from "./PropertyInquiryModal ";
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from "react-router-dom";
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
import BookmarksIcon from '@mui/icons-material/Bookmarks'; // Import filled icon
import AuthModal from './AuthModal';
const BuyPage = () => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(false);
  const [city, setCity] = useState("");
  const [title, setTitle] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [pincode, setPincode] = useState("");
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(100000000);
  const [minArea, setMinArea] = useState(0);
  const [maxArea, setMaxArea] = useState(''); // Example range
  const [selectedPropertyId, setSelectedPropertyId] = useState(null); // State to track the selected property ID
  const [modalOpen, setModalOpen] = useState(false);
  const [isPriceChanged, setIsPriceChanged] = useState(false);
  const [isSearching, setIsSearching] = useState(false); // To track if a search has been performed
  const [wishlistStatus, setWishlistStatus] = useState({});
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // Unified search query

  const location = useLocation();
  const searchResults = location.state?.searchResults || [];
  const [filteredProperties, setFilteredProperties] = useState(properties);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search); // Extract query parameters
    const searchQuery = queryParams.get('search'); // Get 'search' from query params

    if (searchQuery) {
      // Filter properties based on search query (assuming search is based on title or description)
      const filtered = properties.filter((property) =>
        property.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        property.description.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredProperties(filtered);
    } else {
      // Show all properties if no search query is present
      setFilteredProperties(properties);
    }
  }, [location.search, properties]);


  useEffect(() => {
    if (location.state?.searchQuery) {
      setSearchQuery(location.state.searchQuery); // Set search query from HomePage
      handleSearch(location.state.searchQuery); // Perform search based on query
      setIsSearching(true);
    } else {
      getDataFromServer();
    }
  }, [location.state]);

  // Perform search using the search query (from HomePage or within the BuyPage)
  const handleSearch = async (query) => {
    setLoading(true);
    try {
      const response = await ApiService.get(`/admin/search`, {
        params: {
          searchValue: query || searchQuery, // Use provided query or the local state searchQuery
        },
      });
      setProperties(response.data.meta
        ? response.data.meta.properties
        : response.data.data);
      // console.log("khalid",response.data.meta.properties)
      setIsSearching(true); // Active search mode
    } catch (error) {
      console.error("Error during search:", error);
    }
    setLoading(false);
  };


  const getDataFromServer = async () => {
    setLoading(true); // Set loading state to true
    try {
      let successResponse;
      if (city || title || state || country || pincode || isPriceChanged || minPrice || maxPrice || minArea || maxArea) {
        successResponse = await ApiService.getFilter(`/admin/filter`, {
          city, title, state, country, pincode, minPrice, maxPrice, minArea, maxArea
        });
      } else {
        successResponse = await ApiService.getAll("/user/public-listing");
        console.log(successResponse, "amaann");
      }
      const propertiesData = successResponse.data.data || successResponse.data.meta.properties;
      setProperties(propertiesData);
    } catch (error) {
      console.error(error);
    }
    setLoading(false); // Set loading state to false
  };


  const fetchProperties = async () => {
    try {
      const response = await ApiService.getAll('/user/public-listing');
      console.log(response.data.meta.properties, "meta")
      setProperties(response.data.meta.properties); // Assuming the response data is in the 'data' field
    } catch (error) {
      console.error('Error fetching properties:', error);
    }
  };



  useEffect(() => {
    if (searchResults.length > 0) {
      // If search results are passed, use them and mark it as a search
      setProperties(searchResults);
      setIsSearching(true);
    } else {
      // Otherwise, fetch all listings and mark it as not searching
      setIsSearching(false);
      getDataFromServer();
    }
  }, []);


  const handleFilterSubmit = (e) => {
    e.preventDefault();
    setIsSearching(false);
    getDataFromServer();
    setFilterModalOpen(false); // Close filter modal on submit
  };

  const clearFilters = () => {
    setCity("");
    setTitle("");
    setState("");
    setCountry("");
    setPincode("");
    setMinPrice('');
    setMaxPrice('');
    setMinArea(0);
    setMaxArea('');
    setIsSearching(false);
    setFilterModalOpen(false);
    setTimeout(() => {
      fetchProperties();
    }, 0);
  };

  const handlePriceChange = (event, newValue) => {
    setMinPrice(newValue[0]);
    setMaxPrice(newValue[1]);
    setIsPriceChanged(true);

  };

  const handleAreaChange = (event, newValue) => {
    setMinArea(newValue[0]);
    setMaxArea(newValue[1]);
  };

  const openInquiryModal = (propertyId) => {
    setSelectedPropertyId(propertyId);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  const viewAllListings = () => {
    // setIsSearching(false);
    // getDataFromServer(true);
    navigate(`/buyPage`)
  };

  const openFilterModal = () => {
    setFilterModalOpen(true);
  };

  const closeFilterModal = () => {
    setFilterModalOpen(false);
  };


  useEffect(() => {
    const fetchWishlistStatus = async () => {
      try {
        const response = await ApiService.getAll('/user/wishlist');
        console.log(response, 'apiamaan')
        const wishlist = response.data.wishlist;
        const wishlistStatusObj = {};

        wishlist.forEach((item) => {
          wishlistStatusObj[item._id] = true;
        });

        setWishlistStatus(wishlistStatusObj);
      } catch (error) {
        console.error('Error fetching wishlist status:', error);
      }
    };

    fetchWishlistStatus();
    getDataFromServer();  // Ensure your properties are fetched on mount
  }, []);



  const handleWishlistClick = async (propertyId) => {
    const role = localStorage.getItem('role');

    if (role === 'user') {
      try {
        const response = await ApiService.post(`/user/addWishlist`, { propertyId });
        if (response.status === 200) {
          const message = response.data.message;

          setWishlistStatus((prevState) => ({
            ...prevState,
            [propertyId]: message === 'Property added to wishlist',
          }));
        }
      } catch (error) {
        console.error("Error updating wishlist", error);
      }
    } else {
      setShowModal(true); // Show modal if role is not 'user'
    }
  };
  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <Navbar />
      <div className="container-fluid buyPage-background">
        <div className="mx-lg-5 mx-md-5 mx-sm-0">
          <div className="row center-property">
            <div className="col-md-3 col-lg-3  col-sm-none full-width sticky-filter " >
              {/* Filters Column */}
              <Grid item xs={12} md={2} >
                <Box
                  sx={{
                    position: 'sticky', // Make the filter box sticky
                    top: '20px', // Adjust the distance from the top of the viewport
                    padding: 2,
                    paddingBottom: 3,
                    border: '1px solid #ccc',
                    borderRadius: 2,
                    backgroundColor: 'var(--bs-secondary)',
                    marginBottom: 2,
                    marginTop: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: 2,
                    }}
                  >
                    <Typography variant="h6" gutterBottom>
                      Filters
                    </Typography>
                    <Typography
                      sx={{
                        cursor: 'pointer',
                        color: 'var(--bs-primary)',
                        '&:hover': {
                          color: 'var(--bs-accent)',
                          textDecoration: 'underline',
                        },
                      }}
                      onClick={clearFilters}
                    >
                      Clear Filters
                    </Typography>
                  </Box>
                  <form className="buyPageForm" onSubmit={handleFilterSubmit}>
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      label="City"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      sx={{}}
                    />
                    <Typography variant="body1" sx={{ marginTop: 2 }}>Price Range</Typography>
                    <Slider
                      value={[minPrice, maxPrice]}
                      onChange={handlePriceChange}
                      valueLabelDisplay="auto"
                      min={0}
                      max={100000000}
                      step={500000}
                      disableSwap
                      sx={{ marginTop: 1, color: "var(--bs-accent)" }}
                      valueLabelFormat={(value) => {
                        if (value >= 10000000 && value < 100000000) {
                          return `${(value / 10000000).toFixed(2)} Cr`; // 1.05 Cr, 1.35 Cr
                        } else if (value >= 100000000) {
                          return `${(value / 10000000).toFixed(0)} Cr`; // 1 Cr, 2 Cr, ..., 100 Cr
                        } else {
                          return `${(value / 100000).toFixed(0)} Lac`; // 5 Lac, 10 Lac, ...
                        }
                      }}
                    />

                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: 'row' }, // Stack on small screens, align horizontally on medium and larger screens
                        gap: 2, // Space between the text fields
                        marginTop: 1,
                      }}
                    >
                      <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        label="Min Price"
                        type="number"
                        value={minPrice}
                        onChange={(e) => setMinPrice(Number(e.target.value))}
                      />
                      <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        label="Max Price"
                        type="number"
                        value={maxPrice}
                        onChange={(e) => setMaxPrice(Number(e.target.value))}
                      />
                    </Box>


                    {/* Area Range Slider */}
                    <Typography variant="body1" sx={{ marginTop: 1 }}>Area Range (Sq Ft)</Typography>
                    <Slider
                      value={[minArea, maxArea]}
                      onChange={handleAreaChange}
                      valueLabelDisplay="auto"
                      min={0}
                      max={10000}
                      sx={{ marginTop: 1, color: "var(--bs-accent)" }}
                    />

                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: 'row' }, // Stack on small screens, align horizontally on medium and larger screens
                        gap: 2, // Space between the text fields
                        marginTop: 1,
                      }}
                    >
                      <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        label="Min Area"
                        type="number"
                        value={minArea}
                        onChange={(e) => setMinArea(Number(e.target.value))}
                        sx={{ marginTop: 1 }}
                      />
                      <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        label="Max Area"
                        type="number"
                        value={maxArea}
                        onChange={(e) => setMaxArea(Number(e.target.value))}
                        sx={{ marginTop: 1 }}
                      />
                    </Box>



                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      label="Title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      sx={{ marginTop: 1 }}
                    />

                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: 'row' }, // Stack on small screens, align horizontally on medium and larger screens
                        gap: 2, // Space between the text fields
                        marginTop: 1,
                      }}
                    >
                      <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        label="State"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                        sx={{ marginTop: 1 }}
                      />
                      <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        label="Country"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        sx={{ marginTop: 1 }}
                      />
                    </Box>


                    {/* <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      label="Pincode"
                      value={pincode}
                      onChange={(e) => setPincode(e.target.value)}
                      sx={{ marginTop: 1 }}
                    /> */}




                    <Button
                      fullWidth
                      type="submit"
                      variant="contained"
                      onClick={handleFilterSubmit}

                      sx={{
                        marginTop: 1,
                        backgroundColor: 'var(--bs-accent)',
                        '&:hover': { backgroundColor: 'var(--bs-primary)' }
                      }}
                    >
                      <SearchIcon />
                    </Button>

                    {/* <Button
                      fullWidth
                      variant="outlined"
                      onClick={clearFilters}
                      sx={{
                        marginTop: 1,
                        color: 'var(--bs-primary)',
                        borderColor: 'var(--bs-primary)',
                        '&:hover': { backgroundColor: 'var(--bs-accent)', color: '#fff' }
                      }}
                    >
                      Clear Filters
                    </Button> */}

                  </form>
                </Box>
              </Grid>
            </div>

            <div className="col-lg-9 col-sm-12  col-md-9 full-width" >
              {/* search */}
              <div className="col-12 d-flex  px-0 mx-2 hide-searchbar responsiveComponent">
                <input
                  type="text"
                  className="form-control "
                  placeholder="Search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <IconButton
                  onClick={() => handleSearch(searchQuery)}
                  sx={{
                    backgroundColor: 'var(--bs-accent)',
                    '&:hover': {
                      backgroundColor: 'var(--bs-accent)',
                    },
                    color: 'var(--bs-primary)', marginLeft: 1, marginRight: 1, fontSize: '24px',

                  }}
                >
                  <SearchIcon />
                </IconButton>
                <div className="filter-icon">
                  <IconButton onClick={openFilterModal} aria-label="filter">
                    <FilterListIcon
                      sx={{
                        color: 'var(--bs-accent)', fontSize: '24px',

                      }}
                    />
                  </IconButton>
                </div>

              </div>

              {/* Filter Modal for small screens */}
              <Modal
                open={filterModalOpen}
                onClose={closeFilterModal}
                aria-labelledby="filter-modal-title"
                aria-describedby="filter-modal-description"
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '90%', sm: '80%', md: '60%' }, // Responsive width
                    maxHeight: '90vh', // Ensure the modal fits within the viewport
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    overflowY: 'auto', // Allow scrolling if content overflows
                  }}
                >
                  {/* Close Button */}
                  <IconButton
                    aria-label="close"
                    onClick={closeFilterModal}
                    sx={{ position: 'absolute', top: 10, right: 10 }}
                  >
                    <CloseIcon />
                  </IconButton>

                  <Typography id="filter-modal-title" variant="h5" gutterBottom>
                    Filters
                  </Typography>


                  <form onSubmit={handleFilterSubmit}>
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      label="City"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      sx={{ marginTop: 2 }}
                    />
                    <Typography variant="body1" sx={{ marginTop: 2 }}>Price Range</Typography>
                    <Slider
                      value={[minPrice, maxPrice]}
                      onChange={handlePriceChange}
                      valueLabelDisplay="auto"
                      min={0}
                      max={100000000}
                      step={500000}
                      sx={{ marginTop: 2, color: "var(--bs-accent)" }}
                      valueLabelFormat={(value) => {
                        return value >= 10000000
                          ? `${(value / 10000000).toFixed(2)} Cr`
                          : `${(value / 100000).toFixed(0)} Lac`;
                      }}
                    />
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      label="Min Price"
                      type="number"
                      value={minPrice}
                      onChange={(e) => setMinPrice(Number(e.target.value))}
                      sx={{ marginTop: 2 }}
                    />
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      label="Max Price"
                      type="number"
                      value={maxPrice}
                      onChange={(e) => setMaxPrice(Number(e.target.value))}
                      sx={{ marginTop: 2 }}
                    />

                    {/* Area Range Slider */}
                    <Typography variant="body1" sx={{ marginTop: 2 }}>Area Range (Sq Ft)</Typography>
                    <Slider
                      value={[minArea, maxArea]}
                      onChange={handleAreaChange}
                      valueLabelDisplay="auto"
                      min={0}
                      max={10000}
                      sx={{ marginTop: 2, color: "var(--bs-accent)" }}
                    />
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      label="Min Area"
                      type="number"
                      value={minArea}
                      onChange={(e) => setMinArea(Number(e.target.value))}
                      sx={{ marginTop: 2 }}
                    />
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      label="Max Area"
                      type="number"
                      value={maxArea}
                      onChange={(e) => setMaxArea(Number(e.target.value))}
                      sx={{ marginTop: 2 }}
                    />

                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      label="Title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      sx={{ marginTop: 2 }}
                    />
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      label="State"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      sx={{ marginTop: 2 }}
                    />
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      label="Country"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      sx={{ marginTop: 2 }}
                    />
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      label="Pincode"
                      value={pincode}
                      onChange={(e) => setPincode(e.target.value)}
                      sx={{ marginTop: 2 }}
                    />

                    <Button
                      fullWidth
                      type="submit"
                      variant="contained"
                      sx={{
                        marginTop: 2,
                        backgroundColor: 'var(--bs-primary)',
                        '&:hover': { backgroundColor: 'var(--bs-accent)' }
                      }}                  >
                      Apply Filters
                    </Button>
                    <Button
                      fullWidth
                      variant="outlined"
                      onClick={clearFilters}
                      sx={{
                        marginTop: 2,
                        color: 'var(--bs-primary)',
                        borderColor: 'var(--bs-primary)',
                        '&:hover': { backgroundColor: 'var(--bs-accent)', color: '#fff' }
                      }}                  >
                      Clear Filters
                    </Button>
                  </form>
                </Box>
              </Modal>

              {/* Heading: Show only when search is performed */}
              {/* {isSearching && (
                <Typography variant="h4" gutterBottom sx={{ marginLeft: 1, marginBottom: 2, marginTop: 2, color: 'var(--bs-secondary)', fontSize: '30px' }}>
                  Search Results
                </Typography>
              )} */}

              {isSearching && (
                <Button
                  variant="contained"
                  onClick={viewAllListings}
                  sx={{ marginBottom: 2, marginLeft: 1, background: 'var(--bs-accent)', '&:hover': { backgroundColor: 'var(--bs-accent)' }, color: 'var(--bs-primary)', textTransform: 'none', }}
                >
                  View All Listings
                </Button>
              )}

              {/* Property Cards Column */}

              <Grid item xs={12} md={9}>
                <div className="house-card-container">
                  {loading ? (
                    <Grid container justifyContent="center" spacing={4} padding={2}>
                      {/* Loading Skeleton */}
                      {Array.from(new Array(3)).map((_, index) => (
                        <Grid item xs={12} md={9} key={index} sx={{ marginBottom: 2 }}>
                          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', boxShadow: 3, padding: 2 }}>
                            <Box
                              sx={{ width: { xs: '100%', md: 350 }, height: 250, backgroundColor: '#e0e0e0', borderRadius: 1 }}
                            />
                            <Box sx={{ flexGrow: 1, marginLeft: 2 }}>
                              <Typography variant="h6" sx={{ backgroundColor: '#e0e0e0', height: 30, width: '50%' }}></Typography>
                              <Typography variant="body2" sx={{ backgroundColor: '#e0e0e0', height: 15, width: '80%', marginTop: 2 }}></Typography>
                              <Typography variant="body2" sx={{ backgroundColor: '#e0e0e0', height: 15, width: '60%', marginTop: 1 }}></Typography>
                              <Box sx={{ marginTop: 2 }}>
                                <Typography variant="body2" sx={{ backgroundColor: '#e0e0e0', height: 20, width: '40%' }}></Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  ) : properties.length > 0 ? (
                    <Grid justifyContent="center" spacing={4} marginTop={2}>
                      {filteredProperties.length > 0 ? filteredProperties.map((property) => (
                        <Grid item xs={12} md={9} key={property._id} sx={{ marginBottom: 2, cursor: "pointer" }} >
                          <Card sx={{
                            display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', boxShadow: 3, padding: {
                              sm: 1,
                              md: 1,
                              lg: 1,
                            }, borderRadius: 3, backgroundColor: 'var(--bs-secondary)', color: 'var(--bs-primary)', position: 'relative',
                          }}>
                            <CardMedia
                              component="img"
                              sx={{ width: { xs: '100%', md: 350 }, height: { xs: 250, sm: 260, md: 260 }, objectFit: 'cover', borderRadius: 1 }}
                              image={property.images[0]}
                              alt="No Image Avaiable"
                              onClick={() => navigate(`/propertyDescription/${property._id}`)}
                            />
                            <>
                              <div
                                style={{
                                  position: 'absolute',
                                  top: 12,
                                  left: 12,
                                  width: '40px',
                                  height: '40px',
                                  borderRadius: '50%',
                                  backgroundColor: '#d3d3d3', // Gray circle background
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  zIndex: 1,
                                  cursor: 'pointer',
                                }}
                                onClick={() => handleWishlistClick(property._id)}
                              >
                                {wishlistStatus[property._id] ? (
                                  <BookmarksIcon sx={{ color: 'var(--bs-accent)' }} /> // Accent color when added to wishlist
                                ) : (
                                  <BookmarksIcon sx={{ color: '#3d3d3d' }} /> // Outlined icon
                                )}
                              </div>
                              <AuthModal show={showModal} handleClose={handleCloseModal} navigate={navigate} />
                            </>
                            <CardContent sx={{ flexGrow: 1, marginLeft: { xs: 0, md: 2 }, marginTop: { xs: 1, md: 1 } }}
                            //  onClick={() => navigate(`/propertyDescription/${property._id}`)}
                            >
                              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 1 }}>
                                <Typography variant="h6" component="div" >{property.title}</Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                  <CurrencyRupeeIcon sx={{}} />
                                  <Typography variant="h6" sx={{ color: 'var(--bs-primary)', fontWeight: 'bold' }}>{property.price ? `${property.price.toLocaleString('en-IN')}` : "Price not available"}</Typography>
                                </Box>
                              </Box>

                              <Typography variant="body2" color="var(--bs-primary)" gutterBottom sx={{
                                display: '-webkit-box',
                                WebkitLineClamp: 3,           // Limits to 2 lines
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}>{property.description || "No description available."}</Typography>

                              <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <BedOutlinedIcon sx={{ color: '#000', marginRight: 1, fontSize: { xs: '30px', sm: '30px', md: '20px', lg: '16px' } }} />
                                  <Typography variant="body2" sx={{ fontWeight: '' }}><span className="specification">
                                    Bedrooms:
                                  </span>{property.numberOfBedrooms}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <BathtubOutlinedIcon sx={{ color: 'var(--bs-primary)', marginRight: 1, fontSize: { xs: '30px', sm: '30px', md: '20px', lg: '16px' } }} />
                                  <Typography variant="body2" sx={{ fontWeight: '' }}>  <span className="specification">
                                    Bathrooms:
                                  </span> {property.numberOfBathrooms}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <SquareFootIcon sx={{ color: '#000', fontSize: { xs: '30px', sm: '30px', md: '20px', lg: '16px' } }} />
                                  <Typography variant="body2" sx={{ fontWeight: '' }}><span className="specification fw-light">Area:</span>{property.area} Sq Ft</Typography>
                                </Box>
                              </Box>

                              <Box sx={{ marginTop: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>


                                <Button
                                  variant="contained"
                                  onClick={() => navigate(`/propertyDescription/${property._id}`)}

                                  sx={{
                                    backgroundColor: 'var(--bs-accent)',
                                    '&:hover': {
                                      backgroundColor: 'var(--bs-accent)'
                                    },
                                    color: "var(--bs-primary)",
                                    fontSize: {
                                      xs: '0.8rem',
                                      sm: '1rem',
                                      md: '1rem',
                                      lg: '1rem',
                                    },
                                    padding: {
                                      xs: '6px 10px',
                                      sm: '8px 14px',
                                      md: '8px 10px 8px 10px',
                                      lg: '8px 10px 8px 10px;',
                                    },
                                    textTransform: 'none',
                                    marginRight: "3px",
                                    width: "100%"
                                  }}
                                >
                                  View Details
                                </Button>

                                <Button
                                  variant="contained"
                                  onClick={() => navigate(`/propertyDescription/${property._id}`)}

                                  sx={{
                                    backgroundColor: 'var(--bs-accent)',
                                    '&:hover': {
                                      backgroundColor: 'var(--bs-accent)'
                                    },
                                    color: "var(--bs-primary)",
                                    fontSize: {
                                      xs: '0.8rem',
                                      sm: '1rem',
                                      md: '1rem',
                                      lg: '1rem',
                                    },
                                    padding: {
                                      xs: '6px 10px',
                                      sm: '8px 14px',
                                      md: '8px 10px 8px 10px',
                                      lg: '8px 10px 8px 10px;',
                                    },
                                    textTransform: 'none',
                                    marginRight: "3px",
                                    width: "100%"
                                  }}
                                >
                                  360 Image
                                </Button>

                                <Button
                                  variant="contained"
                                  onClick={() => openInquiryModal(property._id)} // Open modal on click

                                  sx={{
                                    backgroundColor: 'var(--bs-accent)',
                                    '&:hover': {
                                      backgroundColor: 'var(--bs-accent)'
                                    },
                                    color: "var(--bs-primary)",
                                    fontSize: {
                                      xs: '0.8rem',  // Extra small screens
                                      sm: '1rem',  // Small screens
                                      md: '1rem',  // Medium screens
                                      lg: '1rem',    // Large screens
                                    },
                                    padding: {
                                      xs: '6px 10px',  // Extra small screens
                                      sm: '8px 14px',  // Small screens
                                      md: '8px 10px 8px 10px', // Medium screens
                                      lg: '8px 10px 8px 10px;', // Large screens
                                    },
                                    textTransform: 'none',

                                    marginRight: "3px",
                                    width: "100%"
                                  }}
                                >
                                  Get a Quote
                                </Button>
                                {/* <Typography variant="body2" sx={{  color: 'text.secondary' }}>Listed by {property.agentName || "Unknown"}</Typography> */}
                              </Box>
                            </CardContent>
                          </Card>
                        </Grid>
                      )) :
                        <div>
                          <Button
                            variant="contained"
                            onClick={viewAllListings}
                            sx={{ marginBottom: 2, marginLeft: 1, background: 'var(--bs-accent)', '&:hover': { backgroundColor: 'var(--bs-accent)' }, color: 'var(--bs-primary)', textTransform: 'none', }}
                          >
                            View All Listings
                          </Button><div className="card m-5 p-5 h-50">
                            <Typography variant="h6" align="center" sx={{ color: 'var(--bs-primary)' }}>
                              No properties found.
                            </Typography>

                          </div>
                        </div>}
                    </Grid>
                  ) : (
                    <div className="card m-5 p-5 h-50">
                      <Typography variant="h6" align="center" sx={{ color: 'var(--bs-primary)' }}>
                        No properties found.
                      </Typography>

                    </div>
                  )}
                </div>
              </Grid>
            </div>

          </div>
        </div>
      </div>






      <PropertyInquiryModal open={modalOpen} handleClose={closeModal} propertyId={selectedPropertyId} />
      <Footer />
    </>
  );
};

export default BuyPage;
