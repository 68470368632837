import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export default function AccordionExpandDefault() {
    return (
        <div className='mb-3 '>
            <Accordion sx={{ backgroundColor: 'var(--bs-secondary)', color: "var(--bs-primary)" }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography>What is Eleganza Estate Solutions, and who is it for?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Eleganza Estate Solutions is exclusively designed for buyers seeking <span style={{ fontWeight: 'bold' }}>Premium Apartments, Plots, and commercial spaces</span> in and around Bhopal .
                        Whether you're looking for your dream home, a valuable plot, or a high-end workspace, we’re here to make the process seamless, transparent, and rewarding .
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion sx={{ backgroundColor: 'var(--bs-secondary)', color: "var(--bs-primary)" }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                >
                    <Typography>What is the Zero Brokerage Policy, and how does it work?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Our Zero Brokerage Policy ensures you don’t pay any commission fees. It’s our way of keeping things transparent and cost-effective, so you can focus on finding the perfect property without worrying about extra costs .
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion sx={{ backgroundColor: 'var(--bs-secondary)', color: "var(--bs-primary)" }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                >
                    <Typography>Do buyers need to pay any fees to Eleganza?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        No, buyers don’t pay any fees at all. With our Zero Brokerage Policy, there are no hidden charges—only premium listings and exceptional service .<br></br>
                        <span style={{ fontWeight: 'bold' }}>"At Eleganza, buying your dream property is free of extra costs—because great investments shouldn't come with high fees."</span>
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion sx={{ backgroundColor: 'var(--bs-secondary)', color: "var(--bs-primary)" }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                >
                    <Typography>What areas and types of properties do you specialise in?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        We specialise in <span style={{ fontWeight: 'bold' }}>luxury apartments, spacious plots, and premium commercial properties </span> across Bhopal and surrounding areas. While Bhopal is our starting point, we aim to <span style={{ fontWeight: 'bold' }}>to expand globally</span>, bringing exclusive real estate opportunities to buyers worldwide .
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion sx={{ backgroundColor: 'var(--bs-secondary)', color: "var(--bs-primary)" }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                >
                    <Typography>What sets Eleganza apart from other real estate agencies?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        We believe in simplicity, transparency, and delivering exceptional service. With our <span style={{ fontWeight: 'bold' }}>Zero Brokerage Policy</span>, there are no hidden fees—just straightforward, commission-free transactions. We also offer a curated selection of luxury properties and personalised guidance to make your real estate journey effortless and stress-free .
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion sx={{ backgroundColor: 'var(--bs-secondary)', color: "var(--bs-primary)" }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                >
                    <Typography>How do you guide buyers?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Our deep understanding of the local market means we can provide clear, actionable advice on property trends, valuations, and opportunities—whether you’re buying a home or investing in real estate .                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion sx={{ backgroundColor: 'var(--bs-secondary)', color: "var(--bs-primary)" }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                >
                    <Typography>How do I get started, and is there a consultation fee?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Starting with us is easy. Reach out through our website or give us a call, and we’ll schedule a free consultation to understand your needs and guide you through your options. We’re here to simplify every step of the process .                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ backgroundColor: 'var(--bs-secondary)', color: "var(--bs-primary)" }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                >
                    <Typography>How do I sell my property or list it on your platform?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        If you’re a property owner looking to sell or showcase your premium apartment, plot, or commercial space, reach out to us directly. We’ll guide you through the process and ensure it reaches discerning buyers .
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
